<template>
<div>
  <div class="home-pc">
        <div class="home">
        <div class="home-header">
           <div class="header-box">
             <div style="display:flex;align-items: center;">
                <img src="@/assets/image/logo.png" alt="" style="width: 117px;">
             <div>
               <ul style="display:flex;margin-left:59px">
                 <li v-for="(item,index) in tabs" :key="index"><span  style="color:#666;margin-right:45px;font-size:16px;cursor:pointer"  @click="getd(index,item.url)" :class="{'active2' :current ==index}">{{item.label}}</span></li>
               </ul>
             </div>
             </div>
             <div style="display:flex;align-items: center;">
               <span style="font-size:14px">客服热线:</span><span style="font-size:14px;color:#0E76FF;margin-left: 8px;margin-right:43px">400-672-1386</span>
               <a href="/yunzhibot" style="color:#000;"><div style="width:84px;height:32px;line-height:32px;text-align:center;background: #FFFFFF;border: 2px solid #0E71EB;border-radius: 16px;cursor:pointer;font-size:14px" >免费体验</div></a>
             </div>
           </div>
           <div>
             <router-view></router-view>
           </div>
           <div style="height:188px">
             <div style="margin-top:30px;display:flex;justify-content:center">
               <div>
                 <img src="@/assets/image/logo.png" alt="" style="width:117px">
                 <p style="font-size: 14px;color: #A6A6A6;line-height: 24px;margin-top:24px">Copyright © 2023 yunzhibot.com All Rights Reserved 杭州红杉云智科技有限公司 <a href="https://beian.miit.gov.cn/" style="color: #A6A6A6;" target="_blank">浙ICP备2023024459号-1 </a> <a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010602013243" style="font-size:14px;color:#A6A6A6">浙公网安备 33010602013243号</a></p>
                 <!-- <p style="font-size: 14px;color: #A6A6A6;">浙ICP备17012895号-1   浙公网安备 33010602009424号</p> -->
                 <p style="font-size: 14px;color: #A6A6A6;margin-top:14px">杭州市西湖区西斗门路9号福地创业园4号楼</p>
               </div>
               <div>
                 <img src="@/assets/image/erweima.png" alt="" style="margin-left:75px">
               </div>
             </div>
           </div>
        </div>
    </div>
  </div>
    <div class="home-yidong">
            <div class="home">
        <div class="home-header">
           <div class="header-box">
             <div style="display:flex;align-items: center;">
                <img src="@/assets/image/logo.png" alt="" style="width: 25%;height:30px">
             <div>
               <ul style="display:flex;margin-left:22px;">
                 <li v-for="(item,index) in tabs" :key="index" ><span  style="color:#666;margin-right:10px;font-size:14px;cursor:pointer;"  @click="getd(index,item.url)" :class="{'active2' :current ==index}">{{item.label}}</span></li>
               </ul>
             </div>
             </div>
             <!-- <div style="display:flex;align-items: center;">
               <span style="font-size:14px">客服热线:</span><span style="font-size:14px;color:#0E76FF;margin-left: 8px;margin-right:13px">400-672-1386</span>
               <a href="/yunzhibot" style="color:#000;"><div style="width:60px;height:32px;line-height:32px;text-align:center;background: #FFFFFF;border: 2px solid #0E71EB;border-radius: 16px;cursor:pointer;font-size:14px" >免费体验</div></a>
             </div> -->
           </div>
           <div>
             <router-view></router-view>
           </div>
           <div style="height:320px">
             <div style="margin-top:30px;display:flex;justify-content:center;margin-left:30px">
               <div>
                 <img src="@/assets/image/logo.png" alt="" style="width:117px">
                 <p style="font-size: 14px;color: #A6A6A6;line-height: 24px;margin-top:8px;width:140%">Copyright © 2023 yunzhibot.com All Rights Reserved 杭州红杉云智科技有限公司 <a href="https://beian.miit.gov.cn/" style="color: #A6A6A6;" target="_blank">浙ICP备2023024459号-1 </a> <a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010602013243" style="font-size:14px;color:#A6A6A6">浙公网安备 33010602013243号</a></p>
                 <!-- <p style="font-size: 14px;color: #A6A6A6;">浙ICP备17012895号-1   浙公网安备 33010602009424号</p> -->
                 <p style="font-size: 14px;color: #A6A6A6;margin-top:14px;width:140%">杭州市西湖区西斗门路9号福地创业园4号楼</p>
               </div>
               <div>
                 <img src="@/assets/image/erweima.png" alt="" style="margin-left:75px">
               </div>
             </div>
           </div>
           <div class="dfooter" style="background-color:#fff;height:60px;position: fixed;bottom: 0;z-index: 1000;width:100%;">
             <div style="width:340px;background-color:#427AF3;height: 40px;border-radius:20px;margin-top:10px;margin-left:25px;text-align:center;line-height:40px">
              <a :href="'tel:' + '400-672-1386'" style="font-size:16px;color:#fff;"> <img src="@/assets/image/dianhua.png" alt="" style="height:20px;vertical-align: middle;margin-top:-6px">
                  <span style="font-size:16px;color:#fff;margin-left:10px">全国统一咨询服务热线: <span>400-672-1386</span></span>
              </a>
             </div>
           </div>
        </div>
    </div>
    </div>
</div>
</template>
<script>(function() {var _53code = document.createElement("script");_53code.src = "https://tb.53kf.com/code/code/32c488d732b9df4ac469d88063ed68463/1";var s = document.getElementsByTagName("script")[0]; s.parentNode.insertBefore(_53code, s);})();</script>
<script>
export default {
  data(){
    return{
      current:0,
      tabs:[
        {
          label:'首页',
          url:'/shouye'
        },
        {
          label:'智能问答',
          url:'/wenjuan'
        },
        {
          label:'3D数智人',
          url:'/shuziren'
        },
        {
          label:'关于我们',
          url:'/about'
        }
      ]
    }
  },
  methods:{
    getd(index,url){
      this.current = index
       this.$router.push(url)
    }
  }
}
</script>
<style lang="scss">
.active2{
  color: #0E76FF !important;
}
html,body .das{ height: 100%;}
  *{
    padding: 0;
    margin: 0;
  }
  .active1{
    color: red;
  }
  //pc端
@media only screen and (min-width: 1200px) {
  .home-pc {
    display: block !important;
   .home{
     width: 100%;
    height: 100vh;
    .home-header{
      height: 80px;
      .header-box{
        padding-left: 47px;
        padding-right:40px;
        height: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .content-chan{
        padding-top:60px;
        height: 506px;
        background-color: #F5FCFF;
        box-sizing: border-box;
      }
      .beiimg{
            height: 576px;
            background-image: url("../../assets/image/bg1.png");
            background-repeat : no-repeat;
            background-size: cover; 
            background-position: center;
      }
      .jian{
        width: 100%;
        height: 542px;
        background: #F5FCFF;
        padding-top:60px;
        box-sizing: border-box;
      }
      .siyou{
        height: 576px;
        background-image: url("../../assets/image/bg2.png");
        background-repeat : no-repeat;
        background-size: 100% 100%;
      }
      .das{
          height: 576px;
          background-image: url("../../assets/image/bg3.png");
          background-repeat : no-repeat;
          background-size: cover; 
          background-position: center center;
      }
      .xuni{
          width: 100%;
          height: 576px;
          background: #FFFFFF;
      }
      .jiand{
          width: 100%;
          height: 488px;
          background-color: #F5FCFF;
          box-sizing: border-box;
          padding-top: 63px;
          display: flex;
          flex-direction: column;
          align-items: center;
      }
      .main{
        width: 100%;
        height: 280px;
        background: #0E76FF;
        flex-direction: column;
        align-items: center;
        padding-top: 63px;
        box-sizing: border-box;
      }
    }
  }
  }
  .home-yidong {
    display: none !important;
    touch-action: auto !important;
  }
}
// 手机端
@media only screen and (max-width: 750px) {
  .home-pc {
    display: none !important;
  }
  .home-yidong {
    display: block !important;
    touch-action: auto !important;
    a{
      text-decoration: none !important;
    }
    .home{
      width: 100%;
    height: 100vh;
    .home-header{
      height: 60px;
      .header-box{
        padding-left: 17px;
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .content-chan{
        padding-top:60px;
        height: 506px;
        background-color: #F5FCFF;
        box-sizing: border-box;
      }
      .beiimg{
            height: 576px;
            background-image: url("../../assets/image/bg1.png");
            background-repeat : no-repeat;
            background-size: cover; 
            background-position: center;
      }
      .jian{
        width: 100%;
        height: 542px;
        background: #F5FCFF;
        padding-top:60px;
        box-sizing: border-box;
      }
      .siyou{
        height: 576px;
        background-image: url("../../assets/image/bg2.png");
        background-repeat : no-repeat;
        background-size: 100% 100%;
      }
      .das{
          height: 576px;
          background-image: url("../../assets/image/bg3.png");
          background-repeat : no-repeat;
          background-size: cover; 
          background-position: center center;
      }
      .xuni{
          width: 100%;
          height: 576px;
          background: #FFFFFF;
      }
      .jiand{
          width: 100%;
          height: 488px;
          background-color: #F5FCFF;
          box-sizing: border-box;
          padding-top: 63px;
          display: flex;
          flex-direction: column;
          align-items: center;
      }
      .main{
        width: 100%;
        height: 280px;
        background: #0E76FF;
        flex-direction: column;
        align-items: center;
        padding-top: 63px;
        box-sizing: border-box;
      }
    }
  }
  }
}
</style>